body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  margin: 0;
  background: #f3f3f3;
  font-family: 'Times New Roman', sans-serif;
}

/* Headers font size */
h1, h2, h3, h4, h5, h6 {
  font-size: 50px;
}

/* Input fields font size */
input {
  font-size: 20px;
}

/* Default text color */
body, input, button, p, h1, h2, h3, h4, h5, h6 {
  color: black;
}

button {
  background-color: rgba(0, 0, 0, .3); /* 30% opacity black background */
  border: none;
  font-size: 20px;
  color: black;
  padding: 10px;
}
