/* ./Categories.css */

.categoriesHeader {
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInFromLeft {
  animation: fadeInFromLeft 1s forwards;
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.categoryItem.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.categoryItem.disabled .cloudscape-header {
  color: #999; 
}

.loadingMessage {
  margin-top: 20px;
  font-size: 18px;
  text-align: center;
}
