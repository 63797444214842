.welcomeMessage {
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.animatedText {
  animation: WelcomeMessage 500ms ease-in 0s 1 normal forwards;
  opacity: 0;
}

.fadeIn {
  animation: fadeIn ease 500ms;
  -webkit-animation: fadeIn ease 500ms;
  -moz-animation: fadeIn ease 500ms;
  -o-animation: fadeIn ease 500ms;
  -ms-animation: fadeIn ease 500ms;
}

@keyframes WelcomeMessage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.submitArrow {
  height: 100%;
  padding: 0.5em 1em;
  font-size: 1rem;
  border: none;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.submitArrow:hover {
  background-color: #555;
}

.customInputWrapper {
  width: 100%;
}

.customInputWrapper > div {
  width: 100%;
}

.customInputWrapper input {
  width: 100%;
}
