.card {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    /* We'll be using the cardFadeIn className for the animation, so we remove the animation property here */
    /* opacity: 0; - Remove this, our keyframes will handle the opacity */
}

@keyframes fadeInFromLeft {
    0% {
        transform: translateX(-50px); /* Adjust this value to control the distance of the movement */
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.cardFadeIn {
    animation: fadeInFromLeft 0.5s ease forwards;
}

.submitWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.submitInput {
    flex: 1;
    margin-right: 10px;
}

.loadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* adjust as needed */
}
